import React from 'react';
import Nav from '../Nav';
import Footer from '../Footer'
import Tickets from '../Tickets';
// VisaServicesPage Component
const VisaServicesPage = () => {
  return (
 <>
 <Nav></Nav>
 <Tickets></Tickets>
<Footer></Footer>

 </>
  );
};

export default VisaServicesPage;
