import React from 'react';
import Nav from '../Nav';
import Footer from '../Footer';
import HotelBooking from '../HotelBooking';

// VisaServicesPage Component
const VisaServicesPage = () => {
  return (
 <>
 <Nav></Nav>
 <HotelBooking></HotelBooking>
<Footer></Footer>

 </>
  );
};

export default VisaServicesPage;
