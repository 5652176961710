import React from 'react';
import Nav from '../Nav';
import Footer from '../Footer';
import HajjUmmrah from '../HajjUmmrah';

// VisaServicesPage Component
const VisaServicesPage = () => {
  return (
 <>
 <Nav></Nav>
 <HajjUmmrah></HajjUmmrah>
<Footer></Footer>

 </>
  );
};

export default VisaServicesPage;
