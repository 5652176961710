import React from 'react';
import Nav from '../Nav';
import Footer from '../Footer';
import VisaServices from '../VisaServices';
import TourPackage from '../TourPackage';

// VisaServicesPage Component
const VisaServicesPage = () => {
  return (
 <>
 <Nav></Nav>
 <TourPackage></TourPackage>
<Footer></Footer>

 </>
  );
};

export default VisaServicesPage;
