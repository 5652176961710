import React from 'react';
import Nav from '../Nav';
import Allservicescom from '../Allservicescomp.js'
import Footer from '../Footer.js';
import Services from '../Services.js';
const AllServices = () => {
  return (
  <>
  <Nav></Nav>
<Services></Services>
<Footer></Footer>
  </>
  );
};

export default AllServices;